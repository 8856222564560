import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

const headerStyle = {
  color: '#fff',
  minHeight: '70px',
  backgroundColor: '#0043ffb8',
  display: 'flex',
  justifyContent: 'center',
}
const navStyle = {
  display: 'flex',
  alignItems: 'center',
}
const navLinkStyle = {
  color: '#fff',
  textDecoration: 'none',
  fontSize: '2rem',
}

const Header = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Yestersay</title>
        <script
          data-ad-client="ca-pub-4305599290552050"
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script>
      </Helmet>
      <header style={headerStyle}>
        <nav style={navStyle}>
          <Link to="/" className={'navLogo'} style={navLinkStyle}>
            Yestersay
          </Link>
        </nav>
      </header>
    </>
  )
}

export default Header
